.platform{
    overflow: hidden;
    position: relative;
}
.platform_head{
    height: 650px;
color: #fff;
width: 100%;
    background-image: linear-gradient(to bottom , #504A93 , #413E65 );
}
.platform_head .head img{
    width: 155px;
    object-fit: contain;
}
.platform_head .head{
    padding-top: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 9;
}
.platform_head .head > div{
    display: flex;
    align-items: center
}
.platform_head .head > div > div{
  margin: 0 26px;
  font-size: 16px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
}
.platform_head .head > div > div a{
    color: #fff;
    cursor: pointer;
    text-decoration: none
}
.platform_head > div .login_btn{
    background: #F48F5C;
    width: 95px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
}

.platform_head > div .login_btn a{
    color: #fff;
    text-decoration: none
}
.platform_head > div .tips{
    color: #F04037;
    position: relative;
}
.platform_head .center{
    margin-top: 94px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.platform_head .platform1 {
    height: 355px;
    position: absolute;
    z-index: 6;right: 58px;
    object-fit: contain
}
.platform_head .platform2 {
    position: absolute;
    width: 100%;
    left: 11px;
    top: -186px;
    object-fit: contain
}
.platform_head .center .tit{
    font-size: 14px;
    width: 380px;
    display: flex;
    flex-direction: column;
    margin-top: 84px;
    line-height: 26px;
}
.platform_head .center h4{
    height: 68px;
font-size: 47px;
font-weight: bold;
margin-bottom: 18px;
}

.platform_callme{
    background: #fff;
    padding: 55px 0 94px;
}
.platform_callme h4{
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 28px;
}
.platform_callme > div{
    display: flex;
}
.platform_callme > div .form{
    width: 424px;
    margin-right: 15%;
}
.platform_callme > div .info{
    width: 211px;
}

.platform_map img{
  width: 103%;
}


.platform_tag1{
    border-top: 1px solid #EAE9F2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.platform_tag1 >div{
    width: 210px;
    margin: 58px 0px 64px;
    color: #696871;
    font-size: 12px;
    line-height: 19px;
}
.platform_tag1 >div .icn img {
    width: 24px;
}
.platform_tag1 >div .tit {
    margin: 28px 0 13px;
    color: #19191B;
    font-size: 16px;
    font-weight: bold;
    font-family: Circular Std-Bold;
}

.platform_tag2{
    border-top: 1px solid #EAE9F2;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 72px;
    align-items: center; 
}
.platform_tag2 img{
    width: 184px;
}

.platform_tag{
    display: flex;
    justify-content: center;
    position: relative;
    background: #F7F7FB;
    align-items: center;
}
.platform_tag .tit{
    color: #19191B;
    font-size: 41px;
    font-family: Circular Std-Bold;
    font-weight: bold;
    line-height: 47px;
}
.platform_tag3 > img {
    width:493px;
    position: absolute;
    top: -393px;
    left: -210px;
}
.platform_tag3 .center_box {
    align-items: center;
}
.platform_tag3 .center_box> div>img {
    width:511px;
    padding: 132px 0 111px;
    margin-right: -81px;
}
.platform_tag .val {
    width: 349px;
    color: #696871;
    margin: 15px 0;
    font-size: 14px;
    line-height: 26px;
}
.platform_tag3 .center_box .tags {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
}
.platform_tag3 .tags .tag-item {
    display: flex;
    width: 48%;
    align-items: center;
    color: #19191B;
    height: 20px;
    margin-bottom: 15px;
    font-size: 14px;
}
.platform_tag3 .tag-item img {
    width: 20px;
    margin: 0 8px 0 0;
    padding: 0;
}
.mr111{
    margin-right: 111px;
}

.platform_tag4{
    background: #fff;
    box-sizing: border-box;
    padding-top: 175px;
    padding-bottom: 140px;
}
.platform_tag4 > img{
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.platform_tag4 .imgs{
    position: relative;
    width: 400px;
    margin-right: 78px;
    height: 390px;
}
.platform_tag4 .imgs img{
    width: 340px;
    position: absolute;
    right: 0;
    top: 0;
}
.platform_tag4 .imgs .img1{
    right: 93px;
    top: -30px;
}
.platform_tag4 .tips{
  margin-top: 30px;
  display: flex;
}
.platform_tag4 .tips .tips-tit{
  width: 230px;
  color: #19191B;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: bold;
}
.platform_tag4 .tips .tips-val{
  width: 230px;
  color: #696871;
  font-size: 12px;
}
.platform_tag4 .tips >img{
    width: 26px;
    height: 26px;
    object-fit: contain;
    margin-right: 24px;
}


.platform_tag5{
    background: #F7F7FB;
    height: 640px;
}
.platform_tag5 > img{
  position: absolute;
  left: 0;
  top: 0px;
  transform: translate(-50%, -50%);
  width: 408px;
}
.platform_tag5 .tit{
    line-height: 57px;
}
.platform_tag5 .imgs{
    margin-left: 23px;
    position: relative;
    /* width: 360px; */
}
.platform_tag5 .imgs img{
    width: 280px;
    margin-right: -90px;
}