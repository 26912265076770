.contactus{
    overflow: hidden;
    position: relative;
}
.contactus_head >.head, .contactus_head >.center, .contactus_callme h4, .contactus_callme > div{
    min-width: 740px;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 20px;
}
.contactus_head{
    height: 650px;
color: #fff;
width: 100%;
    background-image: linear-gradient(to bottom , #504A93 , #413E65 );
}
.contactus_head .head img{
    width: 155px;
    object-fit: contain;
}
.contactus_head .head{
    padding-top: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contactus_head .head > div{
    display: flex;
    align-items: center;
}
.contactus_head .head > div > div a{    
    color: #fff;
    text-decoration: none
}
.contactus_head .head > div > div{
  margin: 0 26px;
  font-size: 16px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
}
.contactus_head > div .tips{
    color: #F04037;
    position: relative;
}

.contactus_head .center_box > div .login_btn{
    background: #F48F5C;
    width: 95px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
}

.contactus_head .center_box > div .login_btn a {
    color: #fff;
    text-decoration: none
}
.contactus_head .center{
    margin-top: 100px;
    display: flex;
}
.contactus_head .center img {
    width: 482px;
    margin-left: -40px;
    object-fit: contain
}
.contactus_head .center > div{
    margin-top: 80px;
    margin-left: 50px; 
    font-size: 14px;
    width: 440px;
    line-height: 26px;
}
.contactus_head .center h4{
    height: 68px;
font-size: 54px;
font-weight: bold;
margin-bottom: 10px;
}

.contactus_callme{
    background: #fff;
    padding: 55px 0 94px;
}
.contactus_callme h4{
    font-size: 28px;
    line-height: 41px;
    width: 920px;
    margin: 0 auto;
    margin-bottom: 28px;
}
.contactus_callme > div{
    display: flex;
    justify-content: start;
}
.contactus_callme > div .form{
    width: 424px;
    margin-right: 15%;
}
.contactus_callme > div .info{
    width: 211px;
}

.contactus_map img{
  width: 103%;
}

.callme_info{
    color: #696871;
    font-size: 14px;
    line-height: 26px;
    font-family: Circular Std-Book;
}
.callme_info .callme_val{
    margin-bottom: 20px;
    height: 78px;
}
.callme_info .callme_title{
    margin-bottom: 20px;
    font-weight: bold;
    color: #19191B;
    font-size: 16px;
}
.callme_form{
    width: 400px;
    margin-right: 86px;
}
.callme_form input, .callme_form input:hover, .callme_form input:focus-visible,.callme_form textarea, .callme_form textarea:hover, .callme_form textarea:focus-visible{
    width: 363px;
    height: 49px;
    border-radius: 4px;
    border: 1px solid #EAE9F2;
    padding: 0 18px;
    line-height: 49px;
    box-sizing: border-box;
    font-size: 14px;
    margin: 11px 0;
    outline: none;
}
.callme_form textarea, .callme_form textarea:hover, .callme_form textarea:focus-visible{
    height: 104px;
    padding: 16px 18px;
    box-sizing: border-box;
    line-height: 24px;
    resize:none
}

.callme_form input::placeholder{
    color: #9C9AAA;
    font-size: 12px;
}
.callme_form >div {
    width: 364px;
    height: 41px;
    background: #f04037;
    text-align: center;
    line-height: 41px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}
