.home{
    overflow: hidden;
    position: relative;
}
.home_head{    
    position: relative;
    padding-top: 41px;
    z-index: 9;
}
.home_head img{
    width: 155px;
    object-fit: contain;
}

.home_head .center_box > div{
    display: flex;
    align-items: center
}
.home_head .center_box > div > div{
  margin: 0 26px;
  font-size: 16px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #19191B;
cursor: pointer;
}
.home_head .center_box > div > div a{
    color: #19191B;
    text-decoration: none
}
.home_head .center_box > div .login_btn{
    background: #F48F5C;
    width: 95px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 3px 3px 3px 3px;
}

.home_head .center_box > div .login_btn a {
    color: #fff;
    text-decoration: none
}
.home_head .center_box > div .tips{
    color: #F04037;
    position: relative;
}
.home_head .center_box > div .tips::before{
    position: absolute;
    content: ' ';
    width: 8px;
    height: 4px;
    background: #F04037;
    right: -30px;
    top: 50%;
    margin-top: -1px;
}

.home_center{
    margin-top: 78px;
    justify-content: center;
}
.home_center img{
    width: 200px;
}
.home_center_img{
    position: relative;
    margin-right: 109px;
}
.home_center_img::before{
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    content: ' ';
    background: url(../../assets/imgs/Shadow@2x.png);
    background-size: 100%;
}
.home_center .home_center_word{
    margin-right: 124px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #19191B;
    line-height: 26px;
}
.home_center_word h4{
    line-height: 47px;
    font-size: 38px;
    font-weight: bold;
    font-family: Circular Std-Bold;
    margin-bottom: 27px;
}
.home_center_word_btn{
    width: 321px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 7px;
    margin-left: 30px;
    margin-top: 40px;
    border: 1px solid #EAE9F2;
    display: flex;
    align-items: center;
    padding: 0 3px 0 14px;
    box-sizing: border-box;
    font-size: 12px;
    color:#696871;
    font-family: Circular Std-Book;
}
.home_center_word_btn img{
    width: 14px;
    margin-right: 18px;
}
.home_center_word_btn span{flex: 1; }
.home_center_word_btn div{
  width: 135px;
  height: 41px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  background: #f48f5c;
  line-height: 41px;
}
.center_tag .title{
    min-width: 740px;
    max-width: 1120px;
    margin: 66px auto 0;
    font-size: 16px;
    color: #696871;
    line-height: 19px;
    height: 19px;
    position: relative;
}
.center_tag .title::before{
    background: #EAE9F2;
    width: 80%;
    content: ' ';
    right: 20px;
    height: 1px;
    bottom: 1px;
    position: absolute;
}
.center_tag .title > span{
    padding: 0 24px 0 0;
    position: absolute;left: 0;
    top: 0px;
    background: #fff;
}
.center_tag .tag{
    text-align: center;
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: center
}
.center_tag .tag img {
    width: 185px;
}

.num_box{
    background: #f48f5c;
    color: #fff;
    font-size: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 37px 0;
}
.num_box  .label img{
    width: 212px;
}
.num_box  .value{
    margin-top: -20px
}
.num_box .num{
    margin: 0 50px 14px;
    font-family: Circular Std-Book;
    font-size: 41px;
    font-weight: bold;
}

.home_tag{
    display: flex;
    justify-content: center;
    height: 635px;
    align-items: center;
    background: #fff;
    position: relative;
}

.home_tag .title {
    font-size: 41px;
    color: #19191B;
    width: 327px;
    margin-bottom: 30px;
}

.home_tag1 .center_box > div > img{
    width: 336px;
}
.home_tag  .tag-li {
    display: flex;
}
.home_tag  .tag-li .tag_tit{
    color: #19191B;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    width: 230px;
}
.home_tag  .tag-li .tag_val{
    color: #696871;
    width: 230px;
    font-size: 12px;
    margin-bottom: 12px;
    min-height: 57px;
}
.home_tag  .tag-li .num{
    width: 33px;
    height: 33px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    background: #f48f5c;
    margin-top: 4px;
    margin-right: 15px;
}

.home_tag2{
    background: #F7F7FB;
}
.home_tag2 > img{
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
}
.home_tag2 .imgs{
    width: 400px;
    height: 420px;
    position: relative;
    margin-right: 50px;
}
.home_tag2 .imgs img{
    position: absolute;
    width: 187px;
    right: 0;
    top: 13px;
}
.home_tag2 .imgs .leftimg{
    width: 378px;
    right: 0;
    top: -10px;
}

.home_tag3 > img {
    position: absolute;
    right: -244px;
    top: -204px;
    width: 408px;
}
.home_tag3 .center_box > div> img {
    width: 402px;
    margin-left: 30px;
}
.home_tag3 .value {
    width: 356px;
    color: #696871;
    font-size: 14px;
    margin-bottom: 30px;
}
.home_tag3 .tags {
    width: 356px;
    color: #19191B;
    display: flex;
    font-size: 14px;
    flex-wrap: wrap;
}
.home_tag3 .tags>div {
    width: 33%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.home_tag3 .tags img{
width: 20px;
margin-right: 8px;
}