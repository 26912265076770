html, body, #root {
  width: 100%;
  height: 100%;
}
*{
  padding: 0;
  margin: 0;
}

#root > div {
  background: #FCFDFE;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('../assets/font/ShiShangZhongHeiJianTi.ttf');
}

.center_box {
  width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}