
.footer_about{
    background: #413e65;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #FFF;
    font-size: 13px;
}
.footer_about h4{
    font-size: 41px;
    text-align: center
}
.footer_about >div{
    display: flex;
    margin: 40px auto 20px;
    justify-content: center;
    align-items: center;
}
.footer_about img{
    width: 183px;
    height: 183px;
    margin-right: 40px;
}
.footer_about >div > div{
    width: 500px;
}
.footer_about >div p{
    font-size: 16px;
}


.footer_getNow{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    /* margin:  */
}
.footer_getNow h4{
    color:#19191B;
    font-size: 41px;
    margin-top: 50px;
    height: 51px;
}
.footer_getNow_test{
    margin: 5px auto 15px;
    color: #696871;
    width: 353px;
    line-height: 26px;
    font-size: 14px;
}
.footer_getNow_img{
    margin: 0 auto 80px;
}
.footer_getNow_img img{
    width: 141px;
    margin: 0 5px;
}

.footer_footer{
    color: #19191B;
    font-style: 15px;
    border-top: 1px solid #EAE9F2;
    text-align: center;
    height: 60px;
    line-height: 60px;
}
